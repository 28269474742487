<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header"> Review Questions</h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="adminRoles.includes(user.role.id)">

                      <!-- modal for confirmation -->
                      <div class="modal fade" id="new_question" role="dialog">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">Create Review Question
                            </div>
                            <form @submit.prevent="createReview()">
                              <div class="modal-body">
                                <div class="error_msgs" v-if="errors">
                                  <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                    <ul>
                                      <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                    </ul>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label" for="mbl_type">Name:</label>
                                  <div class="mt-3">
                                    <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                                    <div class="input_error" v-if="v$.name.$error">
                                      <span v-if="v$.name.required.$invalid"><small>Name is required</small></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label" for="mbl_type">Type:</label>
                                  <div class="mt-3">
                                    <select class="form-control" v-model="type" @blur="v$.type.$type">
                                      <option value="orders">Orders</option>
                                      <option value="items">Items</option>
                                    </select>

                                    <div class="input_error" v-if="v$.type.$error">
                                      <span v-if="v$.type.required.$invalid"><small> Type is required</small></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label" for="mbl_type">Status:</label>
                                  <div class="mt-3">
                                    <select class="form-control" v-model="status" @blur="v$.status.$touch">
                                      <option value="1">Published</option>
                                      <option value="0">Unpublished</option>
                                    </select>
                                    <div class="input_error" v-if="v$.status.$error">
                                      <span v-if="v$.status.required.$invalid"><small>Status is required</small> </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="control-label" for="mbl_type">Question Type:</label>
                                  <div class="mt-3">
                                    <select class="form-control" v-model="questiontype" @blur="v$.questiontype.$type">
                                      <option value="3"> General</option>
                                      <option value="1">Positive</option>
                                      <option value="0">Negative</option>
                                    </select>

                                    <div class="input_error" v-if="v$.questiontype.$error">
                                      <span v-if="v$.questiontype.required.$invalid"><small> Type is required</small></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" id="create_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Create</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <!-- end -->
                      <button @click="createModalData()" data-toggle="modal" data-target="#new_question" class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New Review Question
                      </button>
                    </div>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchReviewQuestions()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="searchReviewQuestions()"  v-model="questionType">
                        <option value="">Select Question Type</option>
                        <option value="1">Positive</option>
                        <option value="0">Negative</option>
                        <option value="3">General</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="searchReviewQuestions()"  v-model="qstatus">
                        <option value="">Select Status</option>
                        <option value="1">Published</option>
                        <option value="0">Unpublished</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="searchReviewQuestions()"  v-model="usedIn">
                        <option value="">Select Used In</option>
                        <option value="orders">Orders</option>
                        <option value="items">Items</option>
                      </select>
                    </div>
                  </div>



                </div>
                
              </div>
              <hr>
            </div>
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="review_table">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <tr>
                          <th width="5px">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Status</th>
                          <th scope="col">Question Type</th>
                          <th scope="col">Used In</th>
                          <th scope="col">Created By</th>
                          <th scope="col">Created At</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="reviewq in reviewQuestions" :key="reviewq.id">
                          <td>{{reviewq.id}}</td>
                          <td>{{reviewq.name}}</td>
                          <td>{{reviewq.status ? 'Published' : 'Unpublished'}}</td>
                          <td>
                            <span v-if="reviewq.question_type ==1">Postive</span>
                            <span v-else-if="reviewq.question_type ==0">Negative</span>
                            <span v-else-if="reviewq.question_type ==3">General</span>
                            <span v-else>N/A</span>
                          </td>
                          <td>{{reviewq.type}}</td>
                          <td>{{reviewq.user_name}}</td>
                          <td>{{ formatDate(reviewq.created_at,1) }}</td>
                          <td>
                            <button class="btn btn-blue btn-sm" @click="updateModalData(reviewq)" data-toggle="modal" :data-target="'#update_question_'+reviewq.id"><i class='fas fa-edit'></i></button>
                            <button class="btn btn-danger btn-sm" data-toggle="modal" :data-target="`#review_delete_${reviewq.id}`"><i class="fa fa-trash"  aria-hidden="true"></i></button>

                            <!-- modal -->
                            <div class="modal fade" :id="`review_delete_${reviewq.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <button type="button" class="close" :id="`review_close_${reviewq.id}`"  data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    Are you sure you want to delete ?
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-danger" @click="deleteQuestion(reviewq.id)"><span v-if="deleting == reviewq.id"><i class='fa fa-spinner fa-spin '></i></span> Delete</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                          </td>
                          <!-- modal for editign -->
                          <div class="modal fade" :id="'update_question_'+reviewq.id" role="dialog">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  Update Review Question
                                </div>
                                <form @submit.prevent="updateReview(reviewq.id)">
                                  <div class="modal-body">
                                    <div class="error_msgs" v-if="errors">
                                      <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                        <ul>
                                          <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                        </ul>
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Name:</label>
                                      <div class="mt-3">
                                        <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                                        <div class="input_error" v-if="v$.name.$error">
                                          <span v-if="v$.name.required.$invalid"><small>Name is required</small></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Type:</label>
                                      <div class="mt-3">
                                        <select class="form-control" v-model="type" @blur="v$.type.$type">
                                          <option value="orders">Orders</option>
                                          <option value="items">Items</option>
                                        </select>

                                        <div class="input_error" v-if="v$.type.$error">
                                          <span v-if="v$.type.required.$invalid"><small> Type is required</small></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Status:</label>
                                      <div class="mt-3">
                                        <select class="form-control" v-model="status" @blur="v$.status.$touch">
                                          <option value="1">Published</option>
                                          <option value="0">Unpublished</option>
                                        </select>
                                        <div class="input_error" v-if="v$.status.$error">
                                          <span v-if="v$.status.required.$invalid"><small>Status is required</small> </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Question Type:</label>
                                      <div class="mt-3">
                                        <select class="form-control" v-model="questiontype" @blur="v$.questiontype.$type">
                                          <option value="3"> General</option>
                                          <option value="1">Positive</option>
                                          <option value="0">Negative</option>
                                        </select>

                                        <div class="input_error" v-if="v$.questiontype.$error">
                                          <span v-if="v$.questiontype.required.$invalid"><small> Type is required</small></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" :id="'update_close_btn'+reviewq.id" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn btn-orange"><span v-if="spinner == reviewq.id"><i class="fa fa-spinner fa-spin"></i></span>Update</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <!--  -->
                        </tr>
                      </tbody>
                      <tfoot v-if="!loading">
                        <tr>
                          <td colspan="8">
                            <div id="pagination" v-if="totalPages > 1">
                              <ul class="showItems">
                                <li>Show Items:
                                  <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="30">30</option>
                                    <option :value="40">40</option>
                                    <option :value="50">50</option>
                                  </select>
                                </li>

                              </ul>
                              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                            </div>

                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div v-if="loading" class="loading-cover">
                    <div class="loader"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            stores: [],
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            reviewQuestions: [],
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            enterpageno: '',
            search: '',
            name: '',
            type: 'orders',
            errors: false,
            errorMsg: [],
            status: 1,
            questiontype: 1,
            debounce: null,
            usedIn: '',
            qstatus: '',
            questionType:'',
            deleting: 0
        }
    },
    validations() {
        return {
            name: { required },
            status: { required },
            type: { required },
            questiontype: { required }
        }
    },
    created: async function () {
        this.getReviewQuestions();
    },
    methods: {
      searchReviewQuestions() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getReviewQuestions();
            }, 1500)
        },
        updateModalData(data) {
            this.name = data.name;
            this.type = data.type;
            this.status = data.status;
            this.questiontype = data.question_type;
        },
        deleteQuestion(id){
          try{
            this.deleting = id;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/delete-review-question/${id}`;
            this.axios.get(url,config)
            document.getElementById(`review_close_${id}`).click();
            toast.success('review question deleted successfully');
            this.deleting = 0;
            this.getReviewQuestions();
          }catch(error){
            this.deleting = 0;
            console.log('error',error)
          }
        },
        createModalData(){
          this.name = '';
          this.type = 'orders';
          this.status = 1;
          this.questiontype = 1;
        },
        async createReview() {
            this.errors = false;
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/create-review-question`;
            const payload = {
                'name': this.name,
                'type': this.type,
                'merchant_id': this.merchantID,
                'status': this.status,
                'question_type': this.questiontype
            }
            try {
                const response = await this.axios.post(url, payload, config)
                if (response.status === 200) {
                    this.getReviewQuestions();
                    toast.success('review question created successfully');
                    document.getElementById('create_close_btn').click();
                }
            } catch (error) {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors;
                }
            }
            this.spinner = false
        },
        async updateReview(id) {
            this.errors = false;
            this.spinner = id;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/update-review-question/${id}`;
            const payload = {
                'name': this.name,
                'type': this.type,
                'merchant_id': this.merchantID,
                'status': this.status,
                'question_type': this.questiontype
            }
            try {
                const response = await this.axios.put(url, payload, config)
                if (response.status === 200) {
                    this.spinner = false
                    this.getReviewQuestions()
                    toast.success('review question updated successfully')
                    document.getElementById('update_close_btn'+id).click();
                    this.spinner = false
                }
            } catch (error) {
                if (error.response.status === 400) {
                    this.errors = true
                    this.errorMsg = error.response.data.errors
                }
            }
            this.spinner = false
        },
        async getReviewQuestions() {
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/review-questions/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            if (this.search != '') {
                url.searchParams.set('search', this.search);
            }
            if(this.usedIn !=''){
              url.searchParams.set('used_in', this.usedIn)
            }
            if(this.qstatus !=''){
              url.searchParams.set('status', this.qstatus)
            }
            if(this.questionType !=''){
              url.searchParams.set('question_type', this.questionType)
            }
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.reviewQuestions = response.data.data.data;
                  this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                  this.totalRecords = response.data.data.total
                  this.loading = false
              }
            }catch(error){
              this.loading = false;
            }
        },
        onPageChange(page) {
            this.page = page
            this.getReviewQuestions();
        },
        onChangeRecordsPerPage() {
            this.getReviewQuestions();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
